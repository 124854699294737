import './App.css';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {useCallback, useEffect, useState} from "react";

const dynamicNumber = '213-927-3700'

function App() {
    const [data, setData] = useState('')
    const [currentEditor, setCurrentEditor] = useState(null)
    const [switchWCTL, setsSwitchWCTL] = useState(false)
    const [switchWCEL, setsSwitchWCEL] = useState(false)
    const [WCTLPost, setWCTLPost] = useState(false)

    useEffect(() => {
        setsSwitchWCTL(localStorage.getItem('switchWCTL') === '1' ? true : false)
        setsSwitchWCEL(localStorage.getItem('switchWCEL') === '1' ? true : false)
        setWCTLPost(localStorage.getItem('WCTLPost') === '1' ? true : false)
    }, [])

    const removeLines = useCallback(() => {
        if (!currentEditor) return
        let newData = data
        if (WCTLPost) {
            newData = newData.replaceAll('<p>&nbsp;</p>', '')
                .replaceAll(/(\[(h|H)1\])|(\[\/(h|H)1\])/g, '') // replace any [h1]{text}[/h1]
                .replaceAll(/(\[(h|H)2\])|(\[\/(h|H)2\])/g, '')
                .replaceAll(/((\+\d{1,2}\s)?|(\d{1,2}-?\s?))\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}/g, dynamicNumber)
                .replaceAll(/(<p>)?(\[(INSERT INFOGRAPHIC HERE|INSERTAR INFOGRAFÍA AQUÍ)\]).+?(?<=(<\/p>))/g, '')
        }
        if (switchWCTL) {
            newData = newData.replaceAll(/(West Coast Trial Lawyers)/gi, 'West Coast Employment Lawyers')
        }
        if (switchWCEL) {
            newData = newData.replaceAll(/(West Coast Employment Lawyer)/gi, 'West Coast Trial Lawyer')
        }
        currentEditor.setData(newData)
        setData(newData)
    }, [currentEditor, data, WCTLPost, switchWCTL, switchWCEL])

    const keydownHandler = useCallback(async function (e) {
        if (e.keyCode === 13 && e.ctrlKey) {
            await removeLines()
            window.scrollTo({
                top: 0
            })
        }
    }, [removeLines])

    useEffect(() => {
        const blob = new Blob([data], {type: 'text/html'});
        const clipboardItem = new window.ClipboardItem({'text/html': blob});
        navigator.clipboard.write([clipboardItem]).then(null)
    }, [data])

    useEffect(() => {
        document.addEventListener('keydown', keydownHandler);
        return () => {
            document.removeEventListener('keydown', keydownHandler);
        }
    }, [keydownHandler])


    return (<div style={{width: "100%", display: "flex"}}>
        <CKEditor
            config={{
                toolbar: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "link",
                    "bulletedList",
                    "numberedList",
                    "blockQuote",
                    "ckfinder",
                    "|",
                    "imageTextAlternative",
                    "imageUpload",
                    "imageStyle:full",
                    "imageStyle:side",
                    "|",
                    "mediaEmbed",
                    "insertTable",
                    "tableColumn",
                    "tableRow",
                    "mergeTableCells",
                    "|",
                    "undo",
                    "redo",
                    "sourceEditing"
                ]
            }}
            editor={ClassicEditor}
            data={data}
            onReady={(editor) => {
                setCurrentEditor(editor)
            }}
            onChange={(event, editor) => {
                console.log(editor.getData())
                setData(editor.getData())
            }}
        />
        <textarea cols="30" rows="10" value={data}>{data}</textarea>
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <button onClick={() => {
                removeLines()
            }} className='remove-btn'>
                Remove break lines
            </button>
            <div style={{display: 'block', marginLeft: "50px", marginTop: "50px"}}>
                <label htmlFor="switchWCTL">Switch WCTL to WCEL</label>
                <input type="checkbox" onChange={event => {
                    const x = !switchWCTL
                    setsSwitchWCTL(x)
                    localStorage.setItem('switchWCTL', x ? '1' : '0')
                }} id='switchWCTL' checked={switchWCTL} value={1}
                       style={{transform: "scale(2)", marginLeft: "10px"}}/>
            </div>
            <div style={{display: 'block', marginLeft: "50px", marginTop: "50px"}}>
                <label htmlFor="switchWCEL">Switch WCEL to WCTL</label>
                <input type="checkbox" onChange={event => {
                    const x = !switchWCEL
                    setsSwitchWCEL(x)
                    localStorage.setItem('switchWCEL', x ? '1' : '0')
                }} id='switchWCEL' checked={switchWCEL} value={1}
                       style={{transform: "scale(2)", marginLeft: "10px"}}/>
            </div>
            <div style={{display: 'block', marginLeft: "50px", marginTop: "50px"}}>
                <label htmlFor="WCTLPost">WCTL POST</label>
                <input type="checkbox" onChange={_ => {
                    const x = !WCTLPost
                    setWCTLPost(x)
                    localStorage.setItem('WCTLPost', x ? '1' : '0')
                }} id='WCTLPost' checked={WCTLPost} value={1}
                       style={{transform: "scale(2)", marginLeft: "10px"}}/>
            </div>
        </div>
    </div>);
}

export default App;
